import type { SVGProps } from 'react';

const SvgCheckboxBorder = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.8722 0C16.6553 0 17.3018 0.185651 17.9537 0.534266C18.6055 0.88288 19.1171 1.39446 19.4657 2.04631C19.8143 2.69816 20 3.34473 20 5.12777V14.8722C20 16.6553 19.8143 17.3018 19.4657 17.9537C19.1171 18.6055 18.6055 19.1171 17.9537 19.4657C17.3018 19.8143 16.6553 20 14.8722 20H5.12777C3.34473 20 2.69816 19.8143 2.04631 19.4657C1.39446 19.1171 0.88288 18.6055 0.534266 17.9537C0.185651 17.3018 0 16.6553 0 14.8722V5.12777C0 3.34473 0.185651 2.69816 0.534266 2.04631C0.88288 1.39446 1.39446 0.88288 2.04631 0.534266C2.69816 0.185651 3.34473 0 5.12777 0H14.8722ZM15.4361 2H4.56389C3.67237 2 3.34908 2.09283 3.02315 2.26713C2.69723 2.44144 2.44144 2.69723 2.26713 3.02315C2.09283 3.34908 2 3.67237 2 4.56389V15.4361C2 16.3276 2.09283 16.6509 2.26713 16.9768C2.44144 17.3028 2.69723 17.5586 3.02315 17.7329C3.34908 17.9072 3.67237 18 4.56389 18H15.4361C16.3276 18 16.6509 17.9072 16.9768 17.7329C17.3028 17.5586 17.5586 17.3028 17.7329 16.9768C17.9072 16.6509 18 16.3276 18 15.4361V4.56389C18 3.67237 17.9072 3.34908 17.7329 3.02315C17.5586 2.69723 17.3028 2.44144 16.9768 2.26713C16.6509 2.09283 16.3276 2 15.4361 2Z"
      fill="#9597A2"
    />
  </svg>
);

export default SvgCheckboxBorder;
