/* Automatically generated file, do not update manually */
import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/RewardsDistributor.json';
import { RewardsDistributor } from 'libs/contracts/generated/infos/contractTypes';
import { useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

interface GetRewardsDistributorContractInput {
  address: string;
  signerOrProvider: Signer | Provider;
}

export const getRewardsDistributorContract = ({
  signerOrProvider,
  address,
}: GetRewardsDistributorContractInput) =>
  new Contract(address, abi, signerOrProvider) as RewardsDistributor;

interface UseGetRewardsDistributorContractInput {
  passSigner: boolean;
  address: string;
  chainId?: ChainId;
}

export const useGetRewardsDistributorContract = ({
  passSigner = false,
  address,
  chainId,
}: UseGetRewardsDistributorContractInput) => {
  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getRewardsDistributorContract({
            address,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, address],
  );
};
