/* Automatically generated file, do not update manually */
import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/Vai.json';
import { Vai } from 'libs/contracts/generated/infos/contractTypes';
import { useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

interface GetVaiContractInput {
  address: string;
  signerOrProvider: Signer | Provider;
}

export const getVaiContract = ({ signerOrProvider, address }: GetVaiContractInput) =>
  new Contract(address, abi, signerOrProvider) as Vai;

interface UseGetVaiContractInput {
  passSigner: boolean;
  address: string;
  chainId?: ChainId;
}

export const useGetVaiContract = ({
  passSigner = false,
  address,
  chainId,
}: UseGetVaiContractInput) => {
  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getVaiContract({
            address,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, address],
  );
};
