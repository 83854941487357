/* Automatically generated file, do not update manually */
import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/VTreasuryV8.json';
import { VTreasuryV8 } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

interface GetVTreasuryV8ContractAddressInput {
  chainId: ChainId;
}

export const getVTreasuryV8ContractAddress = ({ chainId }: GetVTreasuryV8ContractAddressInput) =>
  getUniqueContractAddress({ name: 'VTreasuryV8', chainId });

export const useGetVTreasuryV8ContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getVTreasuryV8ContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetVTreasuryV8ContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getVTreasuryV8Contract = ({
  chainId,
  signerOrProvider,
}: GetVTreasuryV8ContractInput) => {
  const address = getVTreasuryV8ContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as VTreasuryV8) : undefined;
};

interface UseGetVTreasuryV8ContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetVTreasuryV8Contract = (input?: UseGetVTreasuryV8ContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getVTreasuryV8Contract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};
