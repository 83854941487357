/* Automatically generated file, do not update manually */
import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/XvsTokenMultichain.json';
import { XvsTokenMultichain } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

interface GetXvsTokenMultichainContractAddressInput {
  chainId: ChainId;
}

export const getXvsTokenMultichainContractAddress = ({
  chainId,
}: GetXvsTokenMultichainContractAddressInput) =>
  getUniqueContractAddress({ name: 'XvsTokenMultichain', chainId });

export const useGetXvsTokenMultichainContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getXvsTokenMultichainContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetXvsTokenMultichainContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getXvsTokenMultichainContract = ({
  chainId,
  signerOrProvider,
}: GetXvsTokenMultichainContractInput) => {
  const address = getXvsTokenMultichainContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as XvsTokenMultichain) : undefined;
};

interface UseGetXvsTokenMultichainContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetXvsTokenMultichainContract = (input?: UseGetXvsTokenMultichainContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getXvsTokenMultichainContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};
