import type { SVGProps } from 'react';

const Eth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7184 0.636148C12.5348 0.347881 12.2167 0.17334 11.8749 0.17334C11.5331 0.17334 11.215 0.347881 11.0314 0.636148L3.81335 11.9695C3.69891 12.1492 3.64565 12.3593 3.65875 12.569C3.64469 12.7909 3.70417 13.0189 3.84368 13.2137C5.24223 15.1675 6.33367 16.8057 7.43361 18.4569L7.43608 18.4606L7.43618 18.4608C8.53501 20.1103 9.64258 21.7729 11.0618 23.7554C11.2517 24.0207 11.5591 24.1767 11.8853 24.1733C12.2116 24.1699 12.5157 24.0075 12.7 23.7383L19.9181 13.1966C20.0493 13.005 20.1047 12.7839 20.0911 12.5688C20.1041 12.3592 20.0508 12.1491 19.9364 11.9695L12.7184 0.636148ZM15.8744 15.5622L12.3823 17.6184C12.0691 17.8028 11.6806 17.8028 11.3675 17.6184L7.92275 15.5901C8.3235 16.1853 8.71009 16.7656 9.09561 17.3444L9.0981 17.3481C9.97184 18.6597 10.8402 19.9632 11.8614 21.4231L15.8744 15.5622ZM11.8749 15.5962L6.05658 12.1704L11.8749 3.03487L17.6932 12.1704L11.8749 15.5962Z"
      fill="currentColor"
    />
  </svg>
);

export default Eth;
